import { createRouter, createWebHistory } from 'vue-router';
import MainRoutes from './MainRoutes';
import AuthRoutes from './AuthRoutes';
import { useAuthStore } from '@/stores/auth';
import { useCookies } from 'vue3-cookies';

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:pathMatch(.*)*',
      component: () => import('@/views/authentication/Error.vue')
    },
    MainRoutes,
    AuthRoutes
  ]
});

router.beforeEach(async (to, from, next) => {
  const auth = useAuthStore();
  console.log("ROUTER ------", from.path, to.path, auth.user);

  if (to.path == "/auth/login") {
    return next();
  }

  if (auth.user === null) {
    const { cookies } = useCookies();
    if (cookies.get('connect.sid')) {
      try {
        await auth.sessionLogin();
      } catch (error) {
        console.log("Session login failed, redirecting to login page");

        return next('/auth/login');
      }

      return next();
    } else {
      return next('/auth/login');
    }
  } else {
    return next();
  }
});
