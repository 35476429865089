const MainRoutes = {
  path: '/main',
  meta: { requiresAuth: true },
  redirect: '/main',
  component: () => import('@/layouts/full/FullLayout.vue'),
  children: [
    {
      path: '/',
      redirect: "/dashboards/overview"
    },
    {
      name: 'Overview',
      path: '/dashboards/overview',
      component: () => import('@/views/dashboards/overview/Overview.vue')
    },
    {
      name: 'Statistics',
      path: '/dashboards/statistics',
      component: () => import('@/views/dashboards/statistics/Statistics.vue')
    },
    {
      name: 'Speech Development',
      path: '/dashboards/speech-development',
      component: () => import('@/views/dashboards/speechDevelopment/SpeechDevelopment.vue')
    },

    {
      name: 'ChatView',
      path: '/apps/chats',
      component: () => import('@/views/apps/chat/ChatView.vue')
    },
    {
      name: 'Posts',
      path: '/apps/blog/posts',
      component: () => import('@/views/apps/blog/BlogView.vue')
    },
    {
      name: 'BlogPostView',
      path: '/apps/blog/:id',
      component: () => import('@/views/apps/blog/BlogPostView.vue')
    },

    {
      name: 'UserProfile',
      path: '/apps/user/profile',
      component: () => import('@/views/apps/user-profile/Profile.vue')
    }

  ]
};

export default MainRoutes;
