import { useAuthStore } from '@/stores/auth';

export const fetchWrapper = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE')
};

function request(method: string) {
  return (url: string, body?: object) => {
    const headers = new Headers();
    setAuthHeader(url, headers);
    if (body) {
      headers.set('Content-Type', 'application/json');
    }

    const requestOptions: RequestInit = {
      method,
      headers
    };

    if (body) {
      requestOptions.body = JSON.stringify(body);
    }

    // In local development mode, we need to accept cross-origin cookies because frontend and backend
    // are on different ports (therefore different origins).
    // In production this is not the case, and the default value of 'same-origin' should be used.
    const backendURL = new URL(import.meta.env.VITE_API_URL ?? (window.location.origin + '/api'));
    const frontendURL = window.location;
    if (backendURL.origin !== frontendURL.origin) {
      requestOptions.credentials = 'include';
    }

    return fetch(url, requestOptions).then(handleResponse);
  };
}

// helper functions

function setAuthHeader(url: string, headers: Headers) {
  // return auth header with jwt if user is logged in and request is to the api url
  const { user } = useAuthStore();
  const isLoggedIn = !!user?.token;
  const isApiUrl = url.startsWith(import.meta.env.VITE_API_URL);
  if (isLoggedIn && isApiUrl) {
    headers.set('Authorization', `Bearer ${user.token}`);
  }
}

function handleResponse(response: Response) {
  return response.text().then((text: string) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      const { user, logout } = useAuthStore();
      if ([
        401, 403
      ].includes(response.status) && user) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        logout();
      }

      const error = (data && data.message) || response.statusText;

      return Promise.reject(error);
    }

    return data;
  });
}
