import { defineStore } from 'pinia';
import { router } from '@/router';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';

const baseUrl = import.meta.env.VITE_API_URL ?? (window.location.origin + '/api');

type AuthUser = {
  email: string;
  token: string;
};

type AuthState = {
  user: AuthUser | null;
};

export const useAuthStore = defineStore({
  id: 'auth',
  state: (): AuthState => ({
    user: null
  }),
  actions: {
    async login(username: string, password: string) {
      try {
        const user = await fetchWrapper.post(`${baseUrl}/auth/login`, { username, password }) as AuthUser;
        console.log("loggedin user", user);
        this.user = user;
        router.push('/dashboards/overview');
      } catch (error) {
        console.error(error);
        this.user = null;
        router.push('/login');
      }
    },
    async sessionLogin() {
      // This is called by the router to retrieve the logged user info when a session cookie is present.
      // The router will handle an error case (such as session deleted on the server) by redirecting to the login page.
      const user = await fetchWrapper.post(`${baseUrl}/auth/tokenLogin`, { }) as AuthUser;
      this.user = user;
    },
    async logout() {
      await fetchWrapper.post(`${baseUrl}/auth/logout`, {});
      this.user = null;
      router.push('/login');
    }
  }
});
